body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  min-height: 100vh;
}

.app{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.clearfix {
  zoom: 1
}

.clearfix:before, .clearfix:after {
  content: '';
  display: table
}

.clearfix:after {
  clear: both
}

#first-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: #388aff;
  font-size: 18px;
}

.x-table {
  .item-image {
    max-width: 150px;
    max-height: 40px;
    margin: 0 5px;
  }
}

.x-table-toolbar {
  margin-bottom: 16px;

  .ant-collapse-header, h3 {
    font-size: 16px;
    font-weight: bold;
    color: #000 !important;
  }
}

.table-row-operation {
  .ant-btn {
    margin: 2px 16px 5px 0;
  }
}

.borderRadius {
  border-radius: 5px;
  overflow: hidden;
}

.shadowCard {
  position: relative;
  z-index: 1;
  box-shadow: 1px 1px 5px rgba(168, 168, 168, 0.3);
}

.marginTop {
  margin-top: 16px;
}

.marginBottom {
  margin-bottom: 16px;
}

.textAlignCenter {
  text-align: center;
}

.x-table {
  tbody {
    td {
      word-break: break-all
    }
  }
}

.table-search-item {
  .ant-form-item-control-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

$nprogress-color: #1890ff;

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $nprogress-color;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $nprogress-color, 0 0 5px $nprogress-color;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 24px;
  right: 12px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: $nprogress-color;
  border-left-color: $nprogress-color;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


#zmageBackground {
  background-color: rgba(0, 0, 0, .85) !important;
}

.x-table-body {
  .ant-card-body {
    padding: 0;

    .ant-pagination {
      padding-right: 24px;
    }
  }
}


.ant-page-header {
  background: #fff;
  box-shadow: 0 5px 10px hsla(0, 0%, 65.9%, .3);
}
