.table-search {
  .table-search-item {
    margin: 0 0 16px 0;
  }

  .ant-form-item {
    display: flex;

    .ant-form-item-control-wrapper {
      flex: 1 1;
    }
  }
}