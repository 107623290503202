.error-page {
  .error-page-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    min-height: 500px;

    .error-page-title {
      margin-bottom: 24px;
      color: #434e59;
      font-weight: 600;
      font-size: 72px;
      line-height: 72px;
    }

    .error-page-desc {
      margin-bottom: 16px;
      color: rgba(0, 0, 0, .45);
      font-size: 20px;
      line-height: 28px;
    }

    .error-page-btn {
      .ant-btn {
        margin-right: 16px;
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}