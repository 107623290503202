.Login {
  background-image: url("../../assets/login.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;


  .login-content {
    min-height: 90vh;

    .title {
      text-align: center;
    }

    .login-content-col {
      width: 368px;
    }

    @media screen and (max-width: 576px) {
      .login-content-col {
        width: 95%;
      }
    }
  }

  .footer {
    text-align: center;
  }
}