.x-loader {
  width: 300px;
  text-align: center;
  color: #388aff;
  font-size: 16px;

  .x-loader-image {
    width: 100%;
  }

  .x-loader-tip {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
    line-height: 28px;
  }
}